<template>
  <slot name="article" v-bind="$attrs">
    <slot name="article-title">
      <h1>{{ page?.title }}</h1>
    </slot>
    <slot name="article-image-button">
      <div v-if="needLogo && nid && !firstTag" class="d-flex flex-column d-sm-block float-sm-left mr-sm-3">
        <slot name="article-image">
          <div v-if="nick || soft" class="mx-auto my-2 d-sm-flex justify-sm-center">
            <ImageReview
              class="mx-auto my-2"
              :nid="nid"
              :content-type="contentType"
              :download-nick="nick"
              :alt="alt || 'review'"
              :software-title="soft"
            ></ImageReview>
          </div>
        </slot>
        <div v-if="nick || soft" class="mx-auto my-2 d-sm-flex justify-sm-center">
          <slot name="article-button">
            <Button :download-nick="nick" :software-title="soft" :text="page.downloadTitle"></Button>
          </slot>
        </div>
      </div>
    </slot>
    <slot>
      <Element v-bind="page.body" :key="page.uri" />
    </slot>
  </slot>
</template>
<script setup>
import { isFirstTagUziOrImage } from "@/lib/tools.js";
const page = inject("page");
const firstTag = isFirstTagUziOrImage(unref(page)?.body?.childNodes?.[0]);
const _typename = unref(page).typename;
const rels = unref(page).rels;
const nid = rels?.nid;
const contentType = _typename === "NewsArticle" ? rels?.contentType : _typename;
const nick = rels?.downloadNick || "";
const soft = _typename === "SoftwareReview" ? rels?.title : rels?.softwareTitle || "";
const alt = rels?.Title;
const needLogo = ["BrandReview", "SlotsGameReview", "NewsArticle", "SoftwareReview"].includes(_typename);
</script>
